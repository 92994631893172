import styled from '../styled';

export const MerchantTable = styled('div')`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  padding: ${(p) => p.theme.spacing(2)}px 0;

  h3 {
    margin-top: 12px;
  }

  p span {
    text-transform: uppercase;
    font-size: 14px;
    display: block;
    font-weight: bold;
    color: ${(p) => p.theme.palette.grey[400]};
  }

  img {
    display: inline-block;
    max-width: 100%;
    border-radius: 8px;
    border: 1px solid #eee;
  }
`;
