import { Link } from 'gatsby';
import { ResponsiveImage } from "../../../../src/components/ResponsiveImage";
import { BlogTip, BlogQuote } from "../../../../src/components/BlogTip";
import * as React from 'react';
export default {
  Link,
  ResponsiveImage,
  BlogTip,
  BlogQuote,
  React
};