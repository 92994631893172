import React from 'react';
import styled from '../styled';

const TipWrapper = styled('div')`
  background-color: #fffbe6;
  position: relative;
  border-left: 3px solid #ffd666;
  margin: 24px auto;
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(4)}px;
  color: ${(p) => p.theme.palette.grey[800]};
  font-size: 16px;
  line-height: 30px;

  & > strong,
  & > h3,
  & > h4 {
    margin-top: 12px;
    text-transform: uppercase;
  }

  @media (max-width: 800px) {
    margin: 24px;
  }

  code {
    line-height: 12px;
    display: inline-block;
    word-break: break-word;
    background-color: white;
    padding: 2px 4px;
    border-radius: 6px;
  }

  p,
  ul,
  li {
    margin-top: 12px;
    color: ${(p) => p.theme.palette.grey[800]};
    font-size: 16px !important;
    line-height: 30px !important;
  }

  a {
    font-size: inherit !important;
    line-height: 30px;
  }

  &:after {
    position: absolute;
    top: -15px;
    left: -25px;
    width: 50px;
    text-align: center;
    border-radius: 100%;
    height: 48px;
    display: inline-block;
    font-size: 20px;
    line-height: 36px;
    font-weight: 700;
    content: '!';
    color: #d48806;
    background-color: #ffe58f;
    border: 5px solid white;
  }
`;

export const BlogQuote = styled<'div', { src: string }>('div')`
  background-color: #e6f7ff;
  position: relative;
  border-left: 3px solid #40a9ff;
  margin: 24px auto;
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(4)}px;
  color: ${(p) => p.theme.palette.grey[800]};
  font-size: 16px;
  line-height: 30px;

  @media (max-width: 800px) {
    margin: 24px;
  }

  p,
  ul,
  li {
    margin-top: 12px;
    color: ${(p) => p.theme.palette.grey[800]};
    font-size: 16px;
    line-height: 30px;
  }

  code {
    line-height: 12px;
    display: inline-block;
    word-break: break-word;
    background-color: white;
    padding: 2px 4px;
    border-radius: 6px;
  }

  a {
    font-size: inherit !important;
    line-height: 30px;
  }

  &:after {
    position: absolute;
    top: -15px;
    left: -25px;
    width: 50px;
    text-align: center;
    border-radius: 100%;
    height: 48px;
    display: inline-block;
    content: '';
    font-size: 20px;
    line-height: 36px;
    font-weight: 700;
    background: url(${(p) => p.src});
    background-size: cover;
    background-position: center center;
    border: 5px solid white;
  }
`;

export const BlogTip: React.FC = ({ children }) => {
  return <TipWrapper>{children}</TipWrapper>;
};
