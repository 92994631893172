import React from 'react';
import styled from '../styled';

const Picture = styled('picture')`
  img {
    display: block;
  }
`;

const Figure = styled('figure')`
  margin: 0;
`;

const Caption = styled('figcaption')`
  color: ${(p) => p.theme.palette.grey[600]};
  text-align: center;
  font-style: italic;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  a {
    font-size: inherit;
    line-height: inherit;
  }
`;

const ASSET_URL = 'https://assets.affilimate.com';

const toSize = (size: number, src: string) => {
  return `${ASSET_URL}/image/upload/w_${size}/${src}`;
};

type Props = {
  src: string;
  alt: string;
  caption?: string | React.ReactNode;
  loading?: 'lazy' | 'eager';
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const ResponsiveImage = ({
  src,
  alt,
  caption,
  loading,
  ...rest
}: Props) => {
  let newSrc = '';

  if (src.startsWith('/images/')) {
    newSrc = src.substr('/images/'.length, src.length);
  } else {
    newSrc = src;
  }

  if (newSrc.startsWith('/')) {
    newSrc = newSrc.substr(1, newSrc.length);
  }

  if (newSrc.startsWith('https://res.cloudinary.com')) {
    const matches = new RegExp(/v[0-9]+\/(.+)/).exec(newSrc);
    if (matches) {
      newSrc = matches[1];
    }
  }

  const fullSrc = `${ASSET_URL}/${newSrc}`;
  const webPSrc = newSrc.replace('.png', '.webp').replace('.jpg', '.webp');
  const jpgSrc = newSrc.replace('.png', '.jpg');

  return (
    <Figure>
      <Picture>
        <source
          type="image/webp"
          srcSet={`${toSize(1300, webPSrc)} 1300w, ${toSize(
            650,
            webPSrc
          )} 650w, ${toSize(325, webPSrc)} 325w`}
          sizes="100wv"
        />
        <source
          type="image/jpeg"
          srcSet={`${toSize(1300, jpgSrc)} 1300w, ${toSize(
            650,
            jpgSrc
          )} 650w, ${toSize(325, jpgSrc)} 325w`}
          sizes="100wv"
        />
        <img src={fullSrc} alt={alt} loading={loading || 'lazy'} {...rest} />
      </Picture>
      {caption && <Caption>{caption}</Caption>}
    </Figure>
  );
};
